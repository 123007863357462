@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&family=Archivo:wght@400;700&display=swap");

::-webkit-input-placeholder {
  color: #c8c8c8;
}

:-moz-placeholder {
  color: #c8c8c8;
  opacity: 1;
}
::-moz-placeholder {
  color: #c8c8c8;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #c8c8c8;
}
::-ms-clear {
  display: none;
}

:root {
  --main-font: "Archivo", sans-serif;
  --title-font: "Archivo Black", sans-serif;
}

* {
  box-sizing: border-box;
  font-family: var(--main-font);
  font-weight: 400;
  margin: 0;
  padding: 0;
  align-items: center;
}

body {
  background-color: white;
  background-color: #f4f7fc;
  overflow-x: hidden;
  font-size: 16px;
  color: #505060;
  height: 100%;
}

html {
  height: 100%;
}

div#root {
  height: 100%;
}

a,
button,
li,
ol,
p,
span {
  line-height: 1.8em;
  margin: 0;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

p {
  margin-top: 0.6em;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--title-font);
  color: #2d3264;
  line-height: 1em;
  margin: 0;
  letter-spacing: -0.025em;
}

h3,
h4,
h5,
h6 {
  font-family: var(--main-font);
  font-weight: 700;
  line-height: 1.5em;
}

li,
ol {
  list-style-type: none;
  margin-top: 0.5em;
}

h1 {
  font-size: 2.5em;
  color: #1928aa;
  margin: 0.5em auto;
}

h4 {
  font-size: 1.25em;
  color: #1928aa;
  margin-top: 1em;
}

h5 {
  font-size: 1em;
  margin-top: 1em;
}

h6 {
  text-transform: uppercase;
  color: #2ddcb4;
  font-size: 0.8em;
  margin-top: 0.5em;
}

h4:first-child {
  margin-top: 0em;
}

b {
  font-weight: 700;
}

hr {
  border: 0.5px solid #dfdff0;
  margin: 1em 0;
}

.span-intro {
  font-family: var(--main-font);
  font-size: 1.2em;
  color: #1928aa;
  font-weight: 700;
  line-height: 1.5em;
  margin: 2em auto;
  text-align: center;
}


.item-title {
  font-weight: 700;
}
.header {
  background-color: #F4F7FC;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 24px;
  text-align: center;
  width: 100%;
}

.header-container {
  margin: auto;
  max-width: 960px;
}
.button-primary {
  width: 100%;
  height: 56px;
  background: #0064ff;
  border-radius: 8px;
  border: hidden;
  margin-bottom: 5%;
}

.button-primary-text {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}

.prospects-token {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 8px;
  text-align: center;
  letter-spacing: -0.005em;
  color: #747c94;
  word-break: break-all;
}

.prospects-mobile-title {
  color: #151c34;
  font-weight: 700;
}

.prospects-mobile-container {
  color: #151c34;
  text-align: left;
  font-size: 16px;
}

.prospects-mobile-container-title {
  margin-left: 4%;
}

.prospects-mobile-container-desc {
  margin: 5%;
  text-align: left;
}

.Toastify__toast {
  border-radius: 8px !important;
}

.Toastify__toast-container {
  min-width: min(50vh, 50vw);
}

.card {
  max-width: 960px;
  width: 100%;
  margin: auto;
  padding: 2em;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 10px 32px rgba(46, 49, 65, 0.04);
  margin-bottom: 2em;
  text-align: justify;
}

.table {
  margin-top: 1em;
}

.logo-nubi {
  width: 160px;
  max-width: 90%;
  margin: 2em auto;
  position: relative;
}

.line-separator-green {
  background-color: #5affc8;
  content: "";
  display: block;
  height: 2px;
  margin-bottom: 16px;
  margin-top: 12px;
  width: 100%;
}

footer {
  text-align: center;
  padding: 0 0 2em;
  margin: auto;
  max-width: 960px;
  width: 100%;
}

footer p {
  font-size: 0.8em;
  line-height: 1.6em;
}

a {
  text-decoration: none;
  color: #2439ec;
  transition: color 0.25s;
  hyphens: none;
}

a:hover {
  color: #2ddcb4;
  border-bottom: 2px solid;
}

@media (max-width: 320px) {
  body {
    font-size: 0.9em;
  }

  h1 {
    font-size: 1.5em;
  }

  .header {
    padding: 0px;
  }
  .span-intro {
    font-size: 1em;
    margin: 1em auto 1em;
  }

  h4 {
    font-size: 1.25em;
    margin-top: 1.1em;
  }

  .card {
    border-radius: 4px;
    padding: 1em;
    margin-bottom: 1em;
  }

  footer {
    padding: 0.6em;
  }

  html {
    font-size: 14px;
  }
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}
.loader {
  color: #1928aa;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before,
.loader:after {
  position: absolute;
  content: "";
}
.loader:before {
  width: 5.2em;
  height: 10.2em;
  background: #f4f7fc;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.1em 5.1em;
  transform-origin: 5.1em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.loader:after {
  width: 5.2em;
  height: 10.2em;
  background: #f4f7fc;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 4.9em;
  -webkit-transform-origin: 0.1em 5.1em;
  transform-origin: 0.1em 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
